$gutter: 40px
$page-gutter: 80px
$rootlet-width: 81px
$sections: 2
$section-header-width: 42px

$md: 1000px

$view-padding: 5rem
$view-width: 100vw #{' - '}#{$rootlet-width + $sections * ($section-header-width - 3px)}
$-view-width: #{$rootlet-width + $sections * ($section-header-width - 3px)}#{' - '}100vw

$border: #808080
$semi-gray: #111
$bg: #000
$second-text: #b2b2b2

$z-content: 1
$z-section-header: 3
$z-rootlet: 5
$z-rootlet-mobile: 9
$z-modal: 7

$slide-transition-duration: 1s
$slide-transition: transform $slide-transition-duration ease-in-out
$statue-margin: 20rem

$red: #C70B0B
$accent-color: $red
$accent-transparent: transparentize($accent-color, 0.35)

$info-block-color: rgba(255, 255, 255, 0.1)

/* breakpoints */

$xxl: 1920px
$xl: 1600px
$lg: 1400px
$md: 992px
$sm: 576px
$xs: 375px

$mobile-wrapper-padding: 10px